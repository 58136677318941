// $color1: rgb(238, 140, 15);
$color1: rgb(254, 175, 0);
$color2: rgb(248, 212, 66);
$color3: rgb(96, 106, 108);
$color4: rgb(116, 116, 116);
$color5: rgb(248, 249, 250);
$color6: rgb(0, 0, 0);
// $color5: rgba(238, 140, 15, 0.5);
$color7: rgb(254, 175, 0, 0.7);
$color8: rgba(96, 106, 108, 0.9);
$color9: #efefef;

/* BOOTSTRAP */
$primary: $color1;
$secondary: $color2;
$font-family-sans-serif: "Montserrat";
$navbar-light-color: $color1;
$navbar-light-hover-color: $color2;
$navbar-light-active-color: $color2;
$nav-link-font-weight: 600;
$border-radius: 0;
$form-feedback-valid-color: $color1;
$nav-tabs-link-active-color: $color2;
$nav-pills-link-active-bg: #fff;

/* SWEETALERT */
$swal2-confirm-button-background-color: $color1;
$swal2-cancel-button-background-color: $color3;
$swal2-success: $color1;
