@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.mayaup-home{
    h3 {
      font-weight: 600;
      color: v.$color6;
    }
    .card {
        margin-top: 2rem;
        background-color: v.$color5;
        box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.1);
        border-radius: 1.25rem;
        .form-label{
            font-weight: 600;
        }
        .form-control{
            margin-bottom: 1rem;
            border-radius: 0.3rem;
        }
    }
    p {
        font-size: 1rem;
        font-weight: 600;
        color: v.$color3;
      }
    .btn {
        border-radius: 0.3rem;
        border: none;
        color: white;
        width: 50%;
        font-weight: 500;
    }
    .uploaded-file {
        position: relative;
        margin-bottom: 15px;
      
        .delete-icon {
          position: absolute;
          top: -10px;
          right: -10px;
          width: 25px;
          height: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          background-color: rgba(255, 0, 0, 0.8); /* Kırmızı arka plan rengi */
          color: #fff; /* Beyaz renk */
          cursor: pointer;
          transition: background-color 0.3s ease;
      
          &:hover {
            background-color: rgba(255, 0, 0, 1); /* Hover efekti */
          }
        }
      }
}

