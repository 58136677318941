@use "../../../assets/scss/variables" as v;
@use "../../../assets/scss/mixins" as m;

.loginDiv {
  background: linear-gradient(49deg, v.$color1 0%, v.$color2 100%);
  min-height: 100vh;
  @include m.flexbox(column, center, center, 0);
  h3 {
    font-weight: 600;
    color: v.$color6;
  }
  form {
    margin-top: 2rem;
    width: 30vw !important;
    @media (max-width: 992px) {
      width: 50vw !important;
    }
    @media (max-width: 776px) {
      width: 80vw !important;
    }
    @media (max-width: 400px) {
      width: 100vw !important;
    }
    background-color: v.$color5;
    box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.1);
    border-radius: 1.25rem;
    text-align: center;
    max-width: 450px !important;
    h2 {
      font-weight: 700;
      font-size: 1.6rem;
      margin: 0rem 1rem 1rem;
      // white-space: nowrap;
      @media (max-width: 350px) {
        font-size: 1.3rem;
      }
      &::before {
        content: "\007C";
        color: v.$color2;
        font-weight: bold;
        font-size: larger;
        margin-right: 0.5rem;
      }
    }
    h3 {
      font-size: 1.375rem;
      margin: 0;
      font-weight: 600;
      @media (max-width: 350px) {
        font-size: 1.1rem;
      }
    }
    p {
      font-size: 0.875rem;
      font-weight: 400;
      color: v.$color3;
    }
    .form-control {
      background-color: v.$color5;
    }
    .input-group {
      > span.input-group-text {
        border-top-left-radius: 0.3rem;
        border-bottom-left-radius: 0.3rem;
        svg {
          color: v.$color1;
        }
        &:first-child {
          svg {
            font-size: 1.3rem;
          }
        }
      }
      input.form-control {
        &.email-input {
          border-top-right-radius: 0.3rem !important;
          border-bottom-right-radius: 0.3rem !important;
        }
      }
    }
    // .rememberForgot {
    //   @include m.flexbox(row, space-between, center);
    //   .form-check-input {
    //     margin-top: 0.3rem;
    //     font-size: 0.8rem;
    //   }
    //   .form-check-label {
    //     font-size: 0.9rem;
    //     color: v.$color3;
    //   }
    // }
    .form-text {
      font-size: 0.85rem;
      &:nth-child(2) {
        cursor: pointer;
        color: v.$color2;
      }
    }
    // .forgotText {
    //   color: v.$color2;
    //   cursor: pointer;
    //   font-size: 0.9rem;
    // }
    .btn {
      border-radius: 0.3rem;
      border: none;
      color: white;
      width: 50%;
      font-weight: 500;
      // &:last-child {
      //   background-color: v.$color3;
      //   &:hover {
      //     background-color: v.$color1;
      //   }
      // }
    }
  }
}
